<template>

  <div>
    <component
      :is="contentRendered"
      :key="null"

      class="noselect"
      :invoice-data="localInvoiceData"
    /></div>

</template>

<script>
import InvoiceDesignOptions from './design-options'
import calculations from './calculations'

export default {
  components: {

  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },

  },
  data() {
    return {

    }
  },
  computed: {
    localInvoiceData() {
      return calculations.calculateTotals(this.invoiceData)
    },
    contentRendered() {
      let component = null
      InvoiceDesignOptions.themes.forEach(val => {
        if (val.name === this.invoiceData.design.selectedTheme) {
          component = val.component
        }
      })

      return component
    },
  },
  methods: {

  },
}
</script>
<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
</style>
