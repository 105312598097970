<template>

  <div>
    <image-cropper
      v-if="selectedImage"
      :image="selectedImage"
      @cropped="imageCropped"
    />

    <div

      v-for="file,index in uploadFileList.filter(fl=>fl.uploaded==false)"
      :key="index"
      class="progress-wrapper mt-1"
    >
      <b-card-text class="mb-0">

        {{ file.file.name }} {{ $t('is uploading') }} {{ file.uploadProgress+'%' }}
      </b-card-text>
      <b-progress
        v-model="file.uploadProgress"
        max="100"
      />
    </div>
    <b-form-file
      v-show="false"
      id="image_file_path"
      ref="fileUploader"
      v-model="selectedFiles"
      :multiple="mode=='multi'?true:false"
      :accept="accepts"
      :placeholder="$t('Bir dosya seçiniz veya buraya sürükleyiniz')"
      :drop-placeholder="$t('Dosyayı buraya bırakınız...')"
      @input="onFileChange"
    />
  </div>

</template>

<script>
import {
  BFormFile, BCardText, BProgress,
} from 'bootstrap-vue'

// eslint-disable-next-line no-unused-vars
import InvoiceDesignOptions from '@core/components/invoice-themes/design-options'
import axios from '@axios'
import imageCropper from '@core/components/image-cropper/ImageCropper.vue'

export default {
  components: {
    BFormFile,
    BCardText,
    BProgress,
    imageCropper,
  },
  props: {
    showCropper: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'single',
    },
    accepts: {
      type: String,
      default: '',
    },
    fileOption: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      uploadIsProgress: false,
      uploadProgress: 0,
      selectedFiles: null,
      selectedImage: null,
      croppedImage: null,
      uploadFileList: [],
    }
  },
  methods: {
    resetElement() {
      this.uploadFileList = []
      this.selectedFiles = null
      this.selectedImage = null
      this.croppedImage = null
    },
    dataURLtoFile(dataurl, filename) {
      const arr = dataurl.split(',')
      const mime = arr[0].match(/:(.*?);/)[1]
      const bstr = atob(arr[1])
      let n = bstr.length
      const u8arr = new Uint8Array(n)

      // eslint-disable-next-line no-plusplus
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },
    imageCropped(croppedImage) {
      this.croppedImage = croppedImage
      const file = this.dataURLtoFile(this.croppedImage, this.selectedFile.name)
      this.uploadFile(file)
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    onFileChange(event) {
      if (this.mode === 'single') {
        const fileData = {
          file: event, uploaded: false, uploadIsProgress: false, uploadProgress: 0, base64Image: '',
        }
        this.uploadFileList = [fileData]
      } else {
        event.forEach(val => {
          const fileData = {
            file: val, uploaded: false, uploadIsProgress: false, uploadProgress: 0, base64Image: '',
          }
          this.uploadFileList.push(fileData)
        })
      }
      this.selectedFiles = event

      if (this.showCropper) {
        this.getBase64(event).then(data => { this.selectedImage = data })
      } else {
        this.uploadFileList.forEach(val => {
          this.uploadFile(val)
        })
      }
    },
    openUploader() {
      this.$refs.fileUploader.$el.childNodes[0].click()
    },
    uploadFile(file) {
      const formData = new FormData()
      // eslint-disable-next-line no-param-reassign
      file.uploadIsProgress = true
      // eslint-disable-next-line no-param-reassign
      file.uploadProgress = 0
      formData.append('file', file.file)
      formData.append('option', this.fileOption)
      const config = {
        onUploadProgress(progressEvent) {
          // eslint-disable-next-line no-param-reassign
          file.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
      }
      axios
        .post('/api/files', formData, config)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          file.uploaded = true
          //    this.selectedFile = null
          this.$emit('fileUploaded', response.data.data)
          this.resetElement()
        })
        .catch(() => {
          this.showErrorMessage()
        })
        .finally(() => {
          // eslint-disable-next-line no-param-reassign
          file.uploadIsProgress = false
          // eslint-disable-next-line no-param-reassign
          file.uploadProgress = 0
        })
    },
  },

}
</script>
