<template>
  <div>

    <!-- basic modal -->
    <b-modal
      id="cropperModal"
      ref="cropperModal"
      size="lg"
      ok-only
      ok-title="Crop and upload"
      @ok="handleOk"
    >
      <vue-cropper
        v-if="selectedImage"
        ref="cropper"
        :src="selectedImage"
        :view-mode="1"
      />
    </b-modal>

  </div>
</template>

<script>
import { BModal, VBModal } from 'bootstrap-vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueCropper from 'vue-cropperjs'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cropperjs/dist/cropper.css'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BModal,
    VueCropper,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    ratio: { type: Number, default: 4 / 4 },
    image: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedImage: null,

    }
  },
  watch: {
    image(val) {
      if (val != null) {
        this.$refs.cropperModal.show()
      }
    },
  },
  mounted() {
    if (this.image != null) {
      this.selectedImage = this.image

      this.$refs.cropperModal.show()
    }
  },
  methods: {
    handleOk() {
      const croppedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()

      this.$emit('cropped', croppedImage)
    },
  },
}
</script>
